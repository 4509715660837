import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import styles from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
const useStyles = makeStyles(styles);

const Footer = () => {
  const classes = useStyles();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  return <div style={{margin:"0 auto",  padding:"40px",backgroundColor:"#0E0E0E",color:"white"}}>
    <div style={{maxWidth:1200, margin:"0 auto"}}>
   
          <div style={{textAlign:computer?"left":"center",fontSize:18, color:"#A3A3A3"}}>
          <img src={require("assets/img/footerLogo.png").default} style={{height:80,width:337,objectFit:"contain"}}/>
          {computer && <div style={{ float:"right",paddingTop:30}}>   
                  <img src={require(computer?"assets/img/footerLogo2.png":"assets/img/footerLogo4.png").default} 
                    style={{height:computer?25:133,width:"100%",objectFit:"contain"}}/>
              </div>
            }

              <br/>
              NOC 夜貓子俱樂部<br/>
              華語區 Web3 項目工作者社群
            
          </div>
          {!computer && <div style={{ paddingTop:30}}>   
                  <img src={require(computer?"assets/img/footerLogo2.png":"assets/img/footerLogo4.png").default} 
                    style={{height:computer?25:133,width:"100%",objectFit:"contain"}}/>
              </div>
            }
          <div style={{fontSize:10, textAlign:"center", marginTop:60, verticalAlign:"middle", letterSpacing:0}}>
            COPYRIGHT © 2022 FANDORA SHOP ALL RIGHTS RESERVED<br/>
            PROUDLY POWERED BY <img src={require("assets/img/footerLogo3.png").default} style={{height:16,width:60,objectFit:"contain",verticalAlign:"middle"}}/>
            </div>
      </div>
  </div>;
};

export default memo(Footer);
