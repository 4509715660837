import React, { useEffect,useRef }  from "react";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";

import Social from 'features/home/components/Social';
import LandingHeader from "./components/LandingHeader";
import Mint from 'features/home/Mint';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const Intro = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
   const RenderCard = (img,header, title, content, hover)=>{
    const imageRef = useRef(null);
    return(
      <div style={{
        background: "rgba(0, 0, 0, 0.25)",
        border: "1px solid rgba(150, 132, 90, 0.6)",
        boxSizing: "border-box",
        backdropFilter: "blur(7px)",
        borderRadius: "180.5px 180.5px 0px 0px",
        minHeight:650,
        margin:20,
        padding:30,
        width:300,
        textAlign:"center",
        margin:"0 auto"
      }}>
        <img src={require("assets/img/"+img).default} 
        ref={imageRef}
        onMouseOver={()=>{
          if(hover){
            imageRef.current.src = require("assets/img/_"+img).default
        }}}
        onMouseOut={()=>imageRef.current.src=require("assets/img/"+img).default}
        style={{height:221,width:221,objectFit:"contain",borderRadius:50, margin:"0 auto",marginBottom:30}}/>
        <div style={{textAlign:"left"}}>
          <div className="cardHeader">{header}<span className="cardColorContent"> - {title}</span></div>
          <div className="cardContent" style={{whiteSpace: 'pre-line'}}>{content}</div> 
        </div>
    
      </div>
    )
  }
  return (
    <div>
      <LandingHeader color="#E4CC8C"/>
      <div style={{padding:10}}>
      <div style={{textAlign:"center"}}>
          <div >
            <img src={require("assets/img/title.png").default} 
                style={{height:237,width:"100%",objectFit:"contain",marginTop:60}}/>
          </div>
          <div className="subTitle" style={{letterSpacing:15,margin:60}}>
                夜貓子俱樂部
          </div>
          <img src={require("assets/img/line2.png").default} className="line"/>
          <Mint/>
          <img src={require("assets/img/line.png").default} className="line"/>
      </div>
      <div style={{textAlign:"center"}}>
        <div className={"title"}>・Join the Community・</div>
        <div>
        <a
        href={"https://go.fandora.co/1l82x"}
        target="_blank"
      >
        <img src={require("assets/img/discord2.png").default} style={{
            height: 140,
            width:140,
            marginBottom:30,
            verticalAlign: "middle",
            objectFit: "contain"
        }} />
      </a>
      </div>
        <img src={require("assets/img/line.png").default} className="line"/>
      </div>
      <div style={{ marginTop: 60,maxWidth:1100, lineHeight: 0.8, margin:"0 auto" }}>
  
     
          <div className="line" id="about"/>
            <div style={{position:"relative"}}  className="field">
             
              <div className="title">・About・</div> 
              <div className="title" style={{fontSize:computer?36:20}}>華語區 Web3 項目工作者社群</div> 
              <Grid container spacing={computer?2:1} className="field" style={{ margin:"40px auto"}}>
                <Grid item xs={12} md={4} style={{}}>
                  <img src={require("assets/img/NOC_gif.gif").default} style={{height:331,width:"100%",objectFit:"contain"}}/>
                </Grid>
                <Grid item xs={12} md={4} style={{}}>
                  <img src={require("assets/img/noc.png").default} style={{height:68,width:"100%",objectFit:"contain"}}/>
                  <div className="cardHeader" style={{marginTop:"30px", lineHeight:1.4}}>Night Owl Club（NOC） 夜貓子俱樂部，聚集為了項目而熬夜的人們。</div>
                  <div className="cardContent" style={{fontSize: 18}}>專為Web3項目工作者，專注在社群經營的知識與交流為主的半封閉式社群，打造開放且自由的談論風氣，提供經驗分享、實用工具、人才和項目媒合...等，讓華語區的 Web3 項目發展更好且多元。</div>
                </Grid>
                <Grid item xs={12} md={4} style={{textAlign:computer?"left":"center"}}>
                  <div className="cardHeader" style={{paddingBottom:0}}>
                  持有NOC PFP，將可獲得：  </div>
                  <div className="cardColorContent" style={{}}>  
                    <ul>
                      <li>項目工作主題直播講座</li>
                      <li>專屬社群交流獲得最佳解</li>
                      <li>Web3項目發行系統化模板</li>
                    </ul>
                    </div>

                    <div className="cardHeader" style={{paddingBottom:0}}>
                    第一波發行數：150 </div>
                    <div className="cardColorContent" style={{}}>
                    <ul>
                      <li style={{fontFamily: "NotoSerifTC"}}>白名單Mint：</li>
                    2022/5/9 13:30 - 2022/5/10 12:00
                    <li style={{fontFamily: "NotoSerifTC"}}>公開販售：</li>
                    2022/5/10 13:30 - 2022/5/12 12:00
                    </ul>
                    </div>
                </Grid>
              </Grid>
              
            </div>
            <div style={{textAlign:"center"}}>
              <img src={require("assets/img/line.png").default} className="line" id="roadmap"/>
            </div>
            <div style={{position:"relative"}}  className="field">
            <img src={require("assets/img/footprint2.png").default} style={{height:42,width:70,objectFit:"contain",position:"absolute",left:"35%",top:"37%"}}/>
              <div className="title">・Roadmap・</div> 
              <Grid container spacing={computer?8:1} className="field" style={{ margin:"40px auto", position:"relative"}}>
              {!computer&&<img src={require("assets/img/roadMap2.png").default} style={{height:800,width:30,objectFit:"contain",position:"absolute",left:"0",top:"0"}}/>}
                <Grid item xs={12} md={4} style={{paddingLeft:computer?0:35}}>
                <div className="cardHeader">Holders <br/>達0-300人：</div>
                  <div className="cardContent">
                  1.NOC 社群知識+<br/>
                  2.項目工作主題直播講座(賦能)<br/>
                  3.完善的社群運營管理(賦能)<br/>
                  4.Web3 項目發行系統化模板第一波釋出：社群經營(賦能)
                  </div>
                </Grid>
                <Grid item xs={12} md={2} style={{}}></Grid>
                {computer &&<Grid item xs={12} md={4} style={{paddingLeft:computer?0:35}}>
                  <div className="cardHeader">Holders <br/>達501-1000人：</div>
                  <div className="cardContent">
                    1.Web3項目工作者年會<br/>
                    2.Web3實作挑戰<br/>
                    3.Web3項目發行系統化模板第三波釋出：？？？(賦能)</div>
                </Grid>}
              <Grid item xs={12} md={2} style={{paddingLeft:computer?0:35}}>
                {computer && <img src={require("assets/img/footprint.png").default} style={{height:163,width:"100%",objectFit:"contain"}}/>}
                </Grid>
                <Grid item xs={12} md={12} style={{paddingLeft:computer?0:35}}>
                 {computer&&<img src={require("assets/img/roadMap.png").default} style={{height:36,width:"100%",objectFit:"contain"}}/>}
              </Grid>
              <Grid item xs={12} md={2} >

              </Grid>
              <Grid item xs={12} md={4} style={{paddingLeft:computer?0:35}}>
                  <div className="cardHeader">Holders <br/>達301-500人：</div>
                  <div className="cardContent">
 
                1.Web3 人脈生態圈<br/>
                2.三媽社群運營乾貨直播(賦能)<br/>
                3.Web3 項目發行系統化模板第二波釋出：發行規劃(賦能)</div>
                </Grid>
                <Grid item xs={12} md={2} style={{paddingLeft:computer?0:35, marginTop:computer?0:35}}>
                 {!computer && (<><div className="cardHeader">Holders <br/>達501-1000人：</div>
                  <div className="cardContent">
                    1.Web3項目工作者年會<br/>
                    2.Web3實作挑戰<br/>
                  3.Web3項目發行系統化模板第三波釋出：？？？(賦能)</div></>)} 
                </Grid>
              <Grid item xs={12} md={4} style={{paddingLeft:computer?0:35, marginTop:computer?0:35}}>
              <div className="cardHeader">Holders <br/>達1001人以上：</div>
                  <div className="cardContent">
                    1.NOC持有者治理大會<br/>
                    2.Web3項目發行系統化模板第四波釋出：？？？(賦能)
                  </div>
                </Grid>
                
                <Grid item xs={12} md={12} style={{height:120}}></Grid>
                <Grid item xs={12} md={7} style={{background: "rgba(40, 35, 21, 0.5)",
                  border: "1px solid #E4CC8C",
                  padding:computer?25:10,
                  textAlign:computer?"left":"center",
                  lineHeight:1.3,
                  fontFamily:"NotoSerifTC",
                  boxSizing: "border-box"}}>
                <div style={{fontWeight:900, fontSize:14}}>Sustainable Development of Project</div>
                <div >
                  <div style={{fontSize:14, fontFamily:"NotoSerif"}}>NOC應以DAO精神，將NOC治理權賦予NOC holders，以確保永續經營。</div>
                  </div>
                </Grid>
                <Grid item xs={12} md={5} style={{textAlign:"center", padding:10, marginTop:computer?0:30}}>
                <div className="cardContent">*Roadmaps 標註的賦能使用期限至 2023/08/31</div>
                <div style={{fontSize:28, fontWeight:700, marginTop:20, lineHeight:1.2,fontFamily: "NotoSerifTC"}}>詳情請見 NOC Discord</div>
                </Grid>
              </Grid>
            </div>
            <div style={{textAlign:"center"}}>
              <img src={require("assets/img/line.png").default} className="line" id="team"/>
            </div>
            <div style={{position:"relative"}}  className="field">
             
              <div className="title">・Team・</div> 
              <Grid container spacing={computer?2:1} className="field" style={{ margin:"40px auto"}}>
                <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("owl2.png","Sama Tsai","Founder","NFT 社群運營團隊 ComDAO 主理人、NFT discord 社群運營之 DAO 的作者、暴雷降生項目之母、千鳥女王、美女 CMO、區塊鏈追夢人，同時在 web2 經營自媒體「三媽從來不說謊」。",true)}
                  </Grid>
                  <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("owl3.png","Komashiro","Founder","Fandora 共同創辦人，擁有豐富的海內外角色及品牌授權商品化經驗。\n於 2021 年起投身 Web3 領域，成立整合項目發行、Web3 技術、行銷推廣等全方位的新服務品牌「ReBorno」。",true)}
                  </Grid>
                  <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("owl4.png","村長","Artist","hsaio AKA村長\n一位經驗豐富的多媒體設計師，在 NOC 等待有才華的人，一同在 web3 創造新世界。",true)}
                  </Grid>
              </Grid> 
            </div>
   
            <div style={{position:"relative"}}  className="field">
             
              <div className="title">・Partner・</div> 
              <Grid container spacing={computer?2:1} className="field" style={{ margin:"40px auto"}}>
                <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("reborno.png","ReBorno","項目發行","致力於Web3領域，整合項目發行服務、Web3技術服務、行銷推廣服務，擁有項目網站製作、PFP、Sandbox等規劃製作經驗。")}
                  </Grid>
                  <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("fandora.png","Fandora","項目發行","Fandora Shop 為全台最大IP周邊商城，擁有豐富的商品授權經驗，無論是人氣貼圖角色IP、百萬粉絲Youtuber、動漫電玩藝人...都能推出兼具話題性和吸引力的周邊商品，想到IP周邊商品就來Fandora！Color Your Life!")}
                  </Grid>
                  <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("comdao.png","ComDAO","社群運營","NFT社群運營團隊，以擔任項目和社群之間的橋樑為宗旨，客觀忠實地轉譯雙邊需求及想法。\n由資深遊戲社群運營領軍，以系統性方法架構 Web3 社群運營制度，凝聚社群意識，建立社群成員的信任感、歸屬感及認同感。")}
                  </Grid>
                  <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("anftify.png","Anftify","NFT發行平台","為幫助更多IP、藝術家、創作者快速進入區塊鏈世界\nFandora 推出全新品牌服務，一站式協助NFT公售及預熱活動發行結合技術開發與行銷宣傳資源，替項目方大幅縮短前期準備工作時間！")}
                  </Grid>
                  <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("redreamer.png","RE:Dreamer","合作夥伴","RE:Dreamer 在設計一套新的 NFT 協議 並實現對應的 Web3.0 原生 Saas 服務。此協議可輕鬆將目前主流之系統迅速而安全的擴展至 Web3.0，實現在元宇宙交易實體商品的可能。任何人透過 RE:Dreamer 的服務，只需要幾秒鐘就能輕鬆地把 Web2.0 轉移到 Web3.0。")}
                  </Grid>
                  <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("starRocket.png","StarRocket 三創育成基金會","合作夥伴","財團法人三創育成基金會是為新創生態圈而生的非營利組織。使命是讓好奇創新科技產業、想投入創新產業以及想凝聚更多科技、技術同好的人可以達成所望的平台！")}
                  </Grid>
                  <Grid item xs={12} md={2} style={{}}></Grid>
                  <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("fab.png","FAB DAO","合作夥伴","藉區塊鏈最好的特質，在真實世界展開最好的行動。")}
                  </Grid>
                  <Grid item xs={12} md={4} style={{}}>
                    {RenderCard("z.png","The Z Institute","合作夥伴","Z 學院自 2017 年開始提供區塊鏈顧問、企業內訓、專案開發、智能合約審計、國際資源媒合對接等服務。同時開設區塊鏈線上課程和培訓工坊，從教學、培訓、畢業專案製作、成果發表到就業媒合，提供一站式的 Web 3 就職 / 轉職服務。\n我們堅信人才的培訓是產業的基礎，目標是將台灣的人才推向世界舞台，加速帶動台灣區塊鏈的發展。")}
                  </Grid>
              </Grid> 
            </div>
            <div style={{textAlign:"center"}}>
              <img src={require("assets/img/line.png").default} className="line" id="faq"/>
            </div>
            <div style={{position:"relative", textAlign:"center", marginBottom:200}}  className="field">
             
              <div className="title">・FAQ・</div> 
              <div className="cardContent" style={{fontSize:18, textAlign:"center",  fontFamily: "NotoSerifTC"}}>
                任何問題請直接進入 NOC discord 伺服器，<br/>
                感受社群交流的魅力，並藉此獲得解答。
              </div> 
              <div>
                  <a
                  href={"https://go.fandora.co/1l82x"}
                  target="_blank"
                >
                  <img src={require("assets/img/discord2.png").default} style={{
                      height: 140,
                      width:140,
                      margin:60,
                      verticalAlign: "middle",
                      objectFit: "contain"
                  }} />
                </a>
                </div>

            </div>
      </div>
      </div>
    </div>
  );
};

export default Intro;
