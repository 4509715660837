import React from "react";
import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Button from "components/CustomButtons/Button";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: 10,
    padding: "0 15px",
    paddingTop:1,
    paddingRight:0,
    height:40,
    color: "white",

    backgroundColor: "#4D4D4D",
    boxShadow: "10px 10px 10px 1px rgba(0, 0, 0, 0.5)",
  },
  inputRoot: {
    color: "white",
  },
  showDetail: {
    alignItems: "center",
    marginTop: 5,
    marginBottom: 10,
    justifyContent: "space-around",
    width: "100%",
    color: "white",
    backgroundColor: "transparent",
    fontWeight: 600,
    "& .MuiInputBase-root": {
      color: "white",
      letterSpacing:3,
      fontFamily:"OdibeeSans",
    },
    "& fieldset": { color: "white", fontFamily:"OdibeeSans"},
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: theme.palette.background.grey,
      color: "white",
      fontFamily:"OdibeeSans",
      border: `none`,
      "& fieldset": {
        border: `none`,
      },
    },
    outline: "none",
  },
  hovered: {
    "& fieldset": {},
  },
  focused: {
    "& fieldset": {},
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      style={{ fontSize: "20px", padding: 11 }}
      getInputRef={inputRef}
      allowLeadingZeros={false}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

export default function CustomOutlinedInput(props) {
  const commonStyle = useStyles();
  const commonClasses = {
    root: commonStyle.showDetail,
  };

  const { classes, disabled, setMax, text, action, token, placeholder } =
    props;
  const filteredProps = _.omit(props, ["setMax", "startAdornment"]);

  if (token) {
    return (
      <div>
        <div className={commonStyle.field}>
          <TextField
            {...filteredProps}
            placeholder={placeholder || "0.0"}
            classes={Object.assign({}, commonClasses, classes)}
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: props.startAdornment,
              disableUnderline: true,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={commonStyle.inputAdornment}
                >
            
                 
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div className={commonStyle.field}>
          <TextField
            {...filteredProps}
            size="small"
            placeholder={placeholder || ""}
            classes={Object.assign({}, commonClasses, classes)}
            color="secondary"
            InputProps={{
              startAdornment: props.startAdornment,
              disableUnderline: true,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={commonStyle.inputAdornment}
                >
                 <Button color="textField" onClick={action}>
                   <ArrowRightAltIcon style={{color:"#871B1B", fontSize:80}}/>
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
       
      </div>
    );
  }
}

CustomOutlinedInput.defaultProps = {
  fullWidth: true,
};
