import React from "react";
import { useTranslation } from "react-i18next";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Social from './Social';
const checkSelected = (location, linkTo) => {
  if (linkTo.length < 5) return location == "#" + linkTo;
  return location.indexOf("#" + linkTo) >= 0;
};
const renderListItem = (name, color, linkTo, location) => {
  const selected = checkSelected(location, linkTo);
  return (
    <LinkButton
      color={color}
      style={{ padding: 0 }}
      title={name}
      href={linkTo}
      selected={selected}
    />
  );
};
const renderHashListItem = (name, color, linkTo, location) => {
  const selected = checkSelected(location, linkTo);
  return (
    <HashLinkButton
      color={color}
      style={{ padding: 0 }}
      title={name}
      href={linkTo}
      selected={selected}
    />
  );
};
const renderMobileListItem = (name, color, linkTo, handleDrawerToggle) => {
  return (
    <ListItem
      key={name}
      button
      component={HashLink}
      to={linkTo}
      onClick={handleDrawerToggle}
      style={{ marginTop: 10, textAlign: "center", color: color, fontSize: 24 }}
    >
      <ListItemText primary={name} />
    </ListItem>
  );
};
const MenuItems = ({
  handleDrawerToggle,
  style,
  footer = false,
  color = "#00CFFF",
}) => {
  const { t } = useTranslation();
  let currentLocation = window.location.hash;
  return (
    <div style={style}>
      <Hidden mdUp>
      {renderMobileListItem(
          "ABOUT",
          "#E4CC8C",
          "#about",
          handleDrawerToggle
        )}
        {renderMobileListItem(
          "ROADMAP",
          "#E4CC8C",
          "#roadmap",
          handleDrawerToggle
        )}
        {renderMobileListItem(
          "TEAM",
          "#E4CC8C",
          "#team",
          handleDrawerToggle
        )}
       {renderMobileListItem(
          "FAQ",
          "#E4CC8C",
          "#faq",
          handleDrawerToggle
        )}
        <div style={{marginTop:30}}>
        <Social/> 
        </div>
      </Hidden>
      <Hidden smDown>
        <div>
          <Box
            display="flex"
            justifyContent={"flex-start"}
            alignItems={"center"}
            style={{ marginLeft: "-16px", color }}
          >
            {renderHashListItem("About", color, "#about", currentLocation)}
            |
            {renderHashListItem("Roadmap", color, "#roadmap", currentLocation)}
            |
            {renderHashListItem("Team", color, "#team", currentLocation)}
            |
            {renderHashListItem("FAQ", color, "#faq", currentLocation)}
          </Box>
        </div>
      </Hidden>
    </div>
  );
};
const Href = styled.a`
  a:hover {
    text-decoration: none;
  }
`;
const ContainerDiv = styled.div`
  padding-left: 20px;
  a:hover {
    text-decoration: none;
  }
`;
const StyledDiv = styled.div`
  padding: 4px;
  margin: 2px 10px;
  font-family:NotoSerifTC;
  font-size: 16px;
  letter-space: 0px;
  line-height: 24px;
  color: ${(props) => props.color};
  :hover {
  }
`;

function LinkButton({ style, title, href, color, selected }) {
  return (
    <ContainerDiv style={style}>
      <Link
        to={href}
        style={{
          textDecoration: "none",
        }}
      >
        <StyledDiv
          color={color}
          style={{
            // borderBottom: selected ? "2px solid rgba(236,42,56,0.6)" : "0px",
          }}
        >
          {title}
        </StyledDiv>
      </Link>
    </ContainerDiv>
  );
}

function HashLinkButton({ style, title, href, color, selected }) {
  return (
    <ContainerDiv style={style}>
      <HashLink
        to={href}
        style={{
          textDecoration: "none",
        }}
      >
        <StyledDiv
          color={color}
          style={{
            fontFamily: "NotoSerifTC"
            // borderBottom: selected ? "2px solid rgba(236,42,56,0.6)" : "0px",
          }}
        >
          {title}
        </StyledDiv>
      </HashLink>
    </ContainerDiv>
  );
}

export default MenuItems;
