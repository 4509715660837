import React, { useEffect,useState }  from "react";

const renderIcon =(img, link,size,marginRight)=> {
    if(link){
      return(
        <a
        href={link}
        target="_blank"
        style={{marginRight:marginRight}}
      >
        <img src={img} style={{
            height: size,
            width:size,
            verticalAlign: "middle",
            objectFit: "contain"
        }} />
      </a>
      )
    }
  return(
    <img src={img} style={{
      height: size,
      marginRight:marginRight,
      width:size,
      verticalAlign: "middle",
      objectFit: "contain"
  }} />
  )
}
const Social = ({size= 40,marginRight=15,color="", showOpensea= true}) => {
  return (
    <>
    {showOpensea && renderIcon(require(`assets/img/opensea_.png`).default,"",size,marginRight)}
    {renderIcon(require(`assets/img/discord${color.toString()}.png`).default,"https://go.fandora.co/1l82x",size,marginRight)}
   
    </>
  );
};

export default Social;
