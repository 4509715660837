import React, { useEffect,useState }  from "react";
import {Grid, TextField} from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import landingBar from "assets/img/border.png";
import landingBar2 from "assets/img/border2.png";
import Social from 'features/home/components/Social';
import { useConnectWallet, useDisconnectWallet, useFetchMint } from 'features/home/redux/hooks';
import { createWeb3Modal } from 'features/web3';
import { useSnackbar } from 'notistack';
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ConnectWallet from 'components/ConnectWallet/ConnectWallet';
const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor:"#131313",
    color: "white",
    borderRadius: 5,
    fontWeight:700,
    padding:"7px 25px",
    margin:"10px",
    marginTop:30,
    letterSpacing:0,
    verticalAlign:"middle"
  
  },
  header:{
    color:"#E4CC8C", 
    fontSize:40,
    position:"absolute", 
    top:"-30px",left: "50%",
    transform: "translate(-50%, 0)"
  },
  title:{
    color:theme.palette.text.primary,
    fontSize:20,
    fontWeight:500,
    marginTop:25,
    letterSpacing:0
  },
  label:{
    color:"white",
    fontSize:12,
    fontWeight:500,
    letterSpacing:0
  }
}))

const Mint = () => {
  const classes = useStyles();
  const [shortAddress, setShortAddress] = useState('');
  const [count, setCount] = useState(1);
  const {
    web3,
    address,
    connected,
    connectWalletPending,
  } = useConnectWallet();
  const [web3Modal, setModal] = useState(null);
  const [disabled, setDisabled] = useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { fetchMint, fetchMintPending, voucher } = useFetchMint();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));


  const saleType = "PUBLIC_SALE" //TODO: check
  // const saleType = "GUARANTEED_PRE_SALE"
  
  const onMint = () => {
    console.log('onMint', address, saleType)
    fetchMint({
      web3,
      address,
      saleType
    }).then(()=>{
      console.log('voucher', voucher)
    }).catch((error)=> {
      enqueueSnackbar(error, { variant: "error" });
    });    
  };
  return (
    <div>
     
    <Container style={{ marginTop: 60,  letterSpacing: 10,fontFamily:"NotoSerifTC"}}>
    <div className={"title"}>・Mint it Now・</div>
    <div className="title" style={{fontSize:computer?36:20}}>白名單Mint</div> 
      <div style={{position:"relative",height: computer?370 : 600,
      background:`url(${computer?landingBar:landingBar2})`, 
      backgroundSize:computer?"contain":"cover", backgroundRepeat:"no-repeat",
      margin:"70px auto",textAlign:"center", paddingTop:5, backgroundPosition: "center",}}>
            {computer && <img src={require("assets/img/backLogo.png").default} 
         style={{height:500,width:"100%",objectFit:"contain", position:"absolute",left:"55%", top:"25%"}}/>}
        <div style={{fontWeight:700, fontSize:24, margin:50}}>
          <Grid container style={{maxWidth:750, margin:"0 auto"}}>
            <Grid item xs={12} md={3}>
                <div className={classes.title}>PRICE</div>
                <div className={classes.button}>{(0.190*count).toFixed(3)}
                <span className={classes.label} style={{marginLeft:20}}> ETH</span></div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.title} style={{marginBottom:30}}>YOUR ADDRESS</div>
              <ConnectWallet/>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.title}>MINT AMOUNT</div>
              <div className={classes.button}> 
              <IconButton
                  style={{padding:0, marginRight:20}}
                  onClick={()=>{if(count>1)setCount(count-1)}}
                >
                  <ArrowLeftIcon />
                </IconButton>
                {count}
                <IconButton
                style={{padding:0,marginLeft:20}}
                  onClick={()=>{if(count<1)setCount(count+1)}}
                >
                  <ArrowRightIcon />
                </IconButton>
                </div>
            </Grid>
           
          </Grid>
          </div>
       
          <div style={{marginTop:18, position:"absolute", bottom:"0",left:"50%",transform: "translate(-50%, 0)"}}>
            <Button color="disabledButton" style={{fontSize:19, height:60}} onClick={() => {
              // if(!disabled) {
              //   onMint();
              // }
              // else {
              //   enqueueSnackbar("Please connect your wallet", { variant: "error" });
              // }
            }}>MINTING<br/> NOT STARTED </Button></div>
      </div>

    </Container>
      
  </div>
  );
};




export default Mint;
