import { grayColor } from "assets/jss/material-kit-pro-react.js";
const styles = (theme) => ({
  button: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: `${theme.palette.text.green}`,
    color: `white`,
    textTransform: "none",
    // border: `1px solid ${theme.palette.text.green}`,
    height: "36px",
    textDecoration:"none",
    fontWeight: "bold",
    borderRadius: "5px",
    position: "relative",
    padding: "10px 10px",
    margin: 3,
    fontSize: "17px",
    letterSpacing: "0",
    // willChange: 'box-shadow, transform',
    // transition:
    //   'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    // touchAction: 'manipulation',
    cursor: "pointer",
    "& .MuiButton-label": {
      // color: `${theme.palette.text.green}`,
      fontFamily:"NotoSerifTC",
      letterSpacing:0
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    // "& svg": {
    //   position: "relative",
    //   display: "inline-block",
    //   top: "0",
    //   width: "18px",
    //   height: "18px",
    //   marginRight: "4px",
    //   verticalAlign: "middle",
    // },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        marginTop: "0px",
        marginRight: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
      },
    },
  },
  fullWidth: {
    width: "100%",
    margin: 0,
  },
  blueOutlined: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.text.green}`,
    color: `${theme.palette.text.green}`,
    "& .MuiButton-label": {
      color: `${theme.palette.text.green}`,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.text.green}`,
      "& .MuiButton-label": {
        color: "black",
      },
    },
  },
  blue: {
    boxShadow: "5px 5px 5px 1px rgba(0, 0, 0, 0.5)",
    minWidth: 180,
  },
  primary: {
    background: "linear-gradient(180deg, #E4B53A 0%, #FFEE96 100%)",
    borderRadius:16,
    fontFamily:'NotoSerifTC',
    minWidth:240,
    fontSize: 28,
    height: "79px",
    "& .MuiButton-label": {
      color: "black",
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "black",
      },
      background: "linear-gradient(180deg, #E4B53A 0%, #FFEE96 100%)",
    },
  },
  secondary: {
    backgroundColor: "black",
    // border:"1px solid #DD1717",
    borderRadius:30,
    padding:"8px 40px",
    minWidth:140,
    fontSize: 22,
    height:52,
    fontFamily:"NotoSerifTC",

      boxShadow:"0px 0px 12px 4px #DD1717",
    "& .MuiButton-label": {
      color: "#DD1717",
      letterSpacing:0,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: "#DD1717",
      },
      backgroundColor: "black",
    },
  },
  wallet:{
    // background: "linear-gradient(180deg, #E4B53A 0%, #FFEE96 100%)",
    background: 'linear-gradient(180deg, #4F442A 0%, #725D27 100%)',
    fontFamily:"NotoSerifTC",
    borderRadius: 3,
    padding:"7px 15px",
    height:44,
    minWidth:180,
    fontSize:20,
    letterSpacing:0,
    pointerEvents: 'none',
    verticalAlign:"middle",
    "& .MuiButton-label": {
      fontWeight:1000,
      // color: "black",
      color:"#B19341",
      letterSpacing:0
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        fontWeight:1000,
      // color: "black",
      color:"#B19341",
      letterSpacing:0
      },
      // background: "linear-gradient(180deg, #E4B53A 0%, #FFEE96 100%)",
      background: 'linear-gradient(180deg, #4F442A 0%, #725D27 100%)',
    },
  },
  disabledButton:{
    background: 'linear-gradient(180deg, #4F442A 0%, #725D27 100%)',
    backgroundColor:"transparent",
    border: '1px solid #B19341',
    height:60,
    minWidth:165,
    padding:"4px 12px",
    fontSize:20,
    borderRadius:14,
    pointerEvents: 'none',
    "& .MuiButton-label": {
      color:"#B19341"
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color:"#B19341"
      },
      background: 'linear-gradient(180deg, #4F442A 0%, #725D27 100%)',
    }
  },

  textField: {
    border: `none`,
    backgroundColor:"#CBCBCB",

    padding:0,
    fontWeight: 900,
    height:39,
    width:100,
    borderRadius:0,
    margin:0,
    "& .MuiButton-label": {
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
      },
      backgroundColor:"#CBCBCB",
    },
  },
  info: {
    backgroundColor: "transparent",
    borderRadius: 20,
    padding: "0px 20px",
    border: `1px solid ${theme.palette.text.primary}`,
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    "&:hover,&:focus": {
      "& .MuiButton-label": {
        color: theme.palette.text.primary,
      },
      backgroundColor: "transparent",
    },
  },
  transparent: {
    "&,&:focus,&:hover": {
      color: "inherit",
      background: "transparent",
      boxShadow: "none",
    },
  },
  disabled: {
    opacity: "0.65",
    textDecoration: "none",
    "& .MuiButton-label": {
      color: theme.palette.text.primary,
    },
    border: "none",
    backgroundColor: "rgb(221, 221, 221)",

    pointerEvents: "none",
  },
  lg: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "-4px",
      },
    },
    height: "54px",
    padding: "1.125rem 2.25rem",
    fontSize: "1.3rem",
    lineHeight: "1.333333",
  },
  sm: {
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
        marginTop: "1px",
      },
    },
    height: 40,
    minWidth:140,
    fontSize: "1.6rem",
    lineHeight: "1.5",
    borderRadius: "0.2rem",
  },
  round: {
    borderRadius: "30px",
  },
  block: {
    width: "100% !important",
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "transparent",
      color: grayColor[0],
      boxShadow: "none",
    },
  },
  justIcon: {
    paddingLeft: "12px",
    paddingRight: "12px",
    borderRadius: "28px",
    fontSize: "20px",
    height: "56px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px",
      },
      "& svg": {
        width: "32px",
        height: "32px",
      },
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px",
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
  },
});

export default styles;
