import React, { useEffect, useState } from "react";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { Notifier } from "features/common";
import appStyle from "./jss/appStyle.js";
import createAppTheme from "./jss/appTheme";
import background from "assets/img/background.png";
const useStyles = makeStyles(appStyle);

export default function App({ children }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = createAppTheme(true);

  return (
    <StylesProvider>
      <ThemeProvider theme={theme}>
        <div
         style={{
          minHeight:"100vh", 
          backgroundImage: `url(${background})`,
          backgroundRepeat: "repeat",
          overflowX:"hidden"

        }}
        >
          <div style={{margin:"0 auto"
          }}>
            {children}
            {/* <Notifier /> */}
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
}
