import { mintABI, contracts } from "../configure";
import { enqueueSnackbar } from "../common/redux/actions";

export const mint = async ({
  web3,
  address,
  saleType,
  voucher,
  dispatch,
}) => {

  //TODO: check mint contract address
  
  let contractAddress = ""

  if(saleType == 'PUBLIC_SALE') {
    // contractAddress = contracts.mint.address
  }
  else if(saleType == 'GUARANTEED_PRE_SALE') {

  }
  else if(saleType == 'PRE_SALE') {

  }

  const contract = new web3.eth.Contract(
    mintABI,
    contractAddress
  );

  const data = await _mint({
    web3,
    contract,
    address,
    voucher,
    dispatch,
  });
  return data;
};

const _mint = ({ web3, contract, address, voucher, dispatch }) => {
  return new Promise((resolve, reject) => {
    contract.methods
      .mint({address, voucher})
      .send({ from: address })
      .on("transactionHash", function (hash) {
        dispatch(
          enqueueSnackbar({
            message: hash,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: "success",
            },
            hash,
          })
        );
      })
      .on("receipt", function (receipt) {
        resolve();
      })
      .on("error", function (error) {
        reject(error);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
