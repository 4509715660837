import { useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  FETCH_MINT_BEGIN,
  FETCH_MINT_SUCCESS,
  FETCH_MINT_FAILURE,
} from "./constants";
import {
  apiUrl
} from "../../configure";
import { mint } from "../../web3";
import { enqueueSnackbar } from "features/common/redux/actions";
import axios from "axios";

export function fetchMint({ web3, address, saleType }) {
  return (dispatch) => {
    dispatch({
      type: FETCH_MINT_BEGIN,
    });

    const promise = new Promise(async (resolve, reject) => {
      try {        
        let result = await axios.get(`${apiUrl}/api/users/voucher?address=${address.toLowerCase()}&saleType=${saleType}`);
        const voucher = result.data;

        if(voucher) {

          console.log('voucher', voucher)

          mint({web3, address, saleType, voucher, dispatch})
            .then((data) => {
              dispatch({
                type: FETCH_MINT_SUCCESS,
                data,
                voucher,
              });
              dispatch(
                enqueueSnackbar({
                  key: new Date().getTime() + Math.random(),
                  message: "Mint Success",
                  options: {
                    variant: "success",
                  },
                })
              );
              resolve(data);
            })
            .catch((error) => {
              dispatch({
                type: FETCH_MINT_FAILURE,
              });
              reject(error.message || error);
            });
        }
        else {
          dispatch({
            type: FETCH_MINT_FAILURE,
          });
          return reject("Invalid signature");
        }
      }
      catch(error) {
          dispatch({
            type: FETCH_MINT_FAILURE,
          });
          return reject(error.message || error);
      }
    });

    return promise;
  };
}

export function useFetchMint() {
  const dispatch = useDispatch();

  const { voucher, fetchMintPending } = useSelector(
    (state) => ({
      fetchMintPending: state.fetchMintPending,
      voucher: state.home.voucher,
    }),
    shallowEqual
  );

  const boundAction = useCallback(
    (data) => {
      return dispatch(fetchMint(data));
    },
    [dispatch]
  );

  return {
    voucher,
    fetchMint: boundAction,
    fetchMintPending,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_MINT_BEGIN:
      return {
        ...state,
        fetchMintPending: true,
      };

    case FETCH_MINT_SUCCESS:
      return {
        ...state,
        voucher: action.data,
        fetchMintPending: false,
      };

    case FETCH_MINT_FAILURE:
      return {
        ...state,
        fetchMintPending: false,
      };

    default:
      return state;
  }
}
