import React, { useEffect,useState }  from "react";
import {Grid, TextField} from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Container from "@material-ui/core/Container";
import { Redirect } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CustomOutlinedInput from 'components/CustomOutlinedInput/CustomOutlinedInput';
import Social from 'features/home/components/Social';


const Countdown = () => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  useEffect(async() => {
    const id = setInterval(async() => {
    }, 1000);
    return () => clearInterval(id);
}, []);
 
 
  return (

     
      <Container >
            <div style={{ }}>
        <img src={require("assets/img/title.png").default} 
         style={{height:227,width:"100%",objectFit:"contain",marginTop:60}}/>
       <div style={{textAlign:"center", color:"#E4CC8C", marginTop:50, position:"relative"}}>
          <div className="subTitle" style={{letterSpacing:15}}>
            夜貓子俱樂部
          </div>
          <div style={{maxWidth:744, margin:"30px auto",lineHeight:"24px"}}>
          Night Owl Club（NOC） 夜貓子俱樂部，聚集為了項目而熬夜的人們。<br/>
          專為Web3項目工作者，專注在社群經營的知識與交流為主的半封閉式社群，打造開放且自由的談論風氣，提供經驗分享、實用工具、人才和項目媒合...等，讓華語區的 NFT 項目發展更好且多元。

          </div>
          <div className="title">
          2022/03/29<br/>
          Coming soon
          </div>
          <img src={require("assets/img/backLogo.png").default} 
         style={{height:300,width:"100%",objectFit:"contain", position:"absolute",left:"30%", top:"30%"}}/>
          <img src={require("assets/img/line.png").default} className="line"/>
          <div style={{margin:40}}>
              <Social size={120} showOpensea={false}/>
              <div className="subTitle" style={{marginTop:30}}>
                Join Our Discord
              </div>
          
             </div>
             <img src={require("assets/img/line.png").default} className="line"/>
       </div>
     
        
       </div>
      </Container>
        
  );
};

export default Countdown;
