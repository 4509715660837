import React, { useEffect, useRef, useState } from 'react';
import { renderIcon } from '@download/blockies';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import Button from 'components/CustomButtons/Button.js';
import { useTranslation } from 'react-i18next';
import { useConnectWallet, useDisconnectWallet } from 'features/home/redux/hooks';
import styles from './styles';
import { createWeb3Modal } from 'features/web3';
import { useSnackbar } from 'notistack';
const useStyles = makeStyles(styles);

const ConnectWallet = ({}) => {
  const [shortAddress, setShortAddress] = useState('');
  const { connectWallet, web3, address, networkId, connected, connectWalletPending } =
    useConnectWallet();
  const { disconnectWallet } = useDisconnectWallet();
  const [web3Modal, setModal] = useState(null);
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const connectWalletFunction = () => connectWallet(web3Modal);
  const disconnectWalletFunction = () => disconnectWallet(web3, web3Modal);
  // useEffect(async () => {
  //   if (
  //     web3 &&
  //     address &&
  //     !connectWalletPending &&
  //     networkId &&
  //     Boolean(networkId !== Number(process.env.REACT_APP_NETWORK_ID))
  //   ) {
  //     enqueueSnackbar('Network-Error', { variant: 'error' });
  //     try {
  //       await window.ethereum.request({
  //         method: 'wallet_switchEthereumChain',
  //         params: [{ chainId: web3.utils.numberToHex(Number(process.env.REACT_APP_NETWORK_ID)) }],
  //       });
  //     } catch (switchError) {}
  //   }
  // }, [web3, address, networkId, connectWalletPending]);

  // useEffect(() => {
  //   if (!connected) {
  //     return;
  //   }
  //   if (address.length < 11) {
  //     setShortAddress(address);
  //   } else {
  //     setShortAddress(`${address.slice(0, 6)}...${address.slice(-4)}`);
  //   }
  // }, [address, connected]);

  // useEffect(() => {
  //   setModal(createWeb3Modal(t));
  // }, [setModal, t]);

  // useEffect(() => {
  //   if (web3Modal && (web3Modal.cachedProvider || window.ethereum)) {
  //     connectWallet(web3Modal);
  //   }
  // }, [web3Modal, connectWallet]);
  return (
    <Button
      color="wallet"
      // onClick={connected ? disconnectWalletFunction : connectWalletFunction}
    >
      {connected ? shortAddress : 'Connect Wallet'}
    </Button>
  );
};

export default ConnectWallet;
